import { IDeck } from '../models/IDeck';
import { cleanCardMd } from './cleanCardMd';

/**
 *
 */
export const cleanDecks = (decks: IDeck[]) => {
  // --->>

  const newDecks = [...decks].map((deck) => {
    deck.cards = deck.cards.map((card) => cleanCardMd(card));
    return deck;
  });
  return newDecks;
};

import { IDeck } from '../models/IDeck';
import { cardToHash } from './cardToHash';

/**
 *
 */
export const flagSkippedDecks = (decks: IDeck[], hashes: string[]) => {
  // --->>

  const newDecks = [...decks].map((deck) => {
    deck.cards.forEach((card) => {
      const hash = cardToHash(card);
      if (hashes.includes(hash)) {
        card.skip = true;
      }
    });
    return deck;
  });
  return newDecks;
};

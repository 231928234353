import { TSupplement } from '../models/TSupplement';

/**
 * Supplement can be a string, an array of strings,
 * or an array of array of strings, etc.
 * Need to convert this to a string!
 */
export const supplementToString = (supplement: TSupplement, indent = ''): string => {
  // --->>

  if (typeof supplement === 'string') return `${supplement}`;

  return supplement
    .map((s) => {
      //
      if (Array.isArray(s)) return supplementToString(s, indent + '\t');
      return `${indent}- ${s}`;
    })
    .join('\n');
};

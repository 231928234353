import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { IonIcon } from '@ionic/react';
import {
  //
  alertCircleOutline,
  thumbsDownOutline,
  thumbsUpOutline,
} from 'ionicons/icons';

import { ICard } from '../../models/ICard';
import { IMySwiper } from '../../models/IMySwiper';
import { ActiveCardsContext } from '../../providers/ActiveCardsProvider';
import { generateCards } from '../../utils/cardsGenerator';
import { BasicCard } from '../BasicCard';
import { cardToHash } from '../../utils/cardToHash';

import styles from './styles.module.scss';

interface IProps {
  closeModal: () => void;
}

const initFakeCards = generateCards(10);
const isFakeCards = !true;

/* The 'delta' of cards we want to have selected at end of pack */
const dCards = 5;

/**
 * Displays the pack of cards that is opened using a swiper
 * Also encodes card-replacement logic
 * Very possibly the trickiest/most-important component in this project
 *
 * Logic Overview: we keep track of two sets of cards: 'initCards' and 'displayCards'
 * The initCards are just the original pack as delivered from state
 * The displayCards is the pack that gets modified based on user behavior; we want
 * the user to be able to replace a card (in order to review it soon), and we want
 * the user to be able to go back over the cards viewed in the exact order they were
 * viewed first time. This is achieved by building up displayCards gradually.
 *
 * The logic requires that have two variables to keep track of card positions within
 * each of these two packs: `initCardsIndex`, `displayedCardsIndex`.
 *
 */
export const Cards: React.FC<IProps> = ({ closeModal }) => {
  // --->>

  const { activeCardsState } = useContext(ActiveCardsContext);
  const initCards: ICard[] = isFakeCards
    ? initFakeCards
    : placeCardAtFrontOfPack(activeCardsState.cards, activeCardsState.currentCard);

  const initNumberOfDisplayCards = initCards.length < dCards ? initCards.length : dCards;

  const [displayCards, setDisplayCards] = useState<ICard[]>(
    initCards.filter((_, ind) => ind < initNumberOfDisplayCards)
  );

  const [initCardsIndex, setInitCardsIndex] = useState<number>(initNumberOfDisplayCards);
  const [displayedCardsIndex, setDisplayedCardsIndex] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<IMySwiper>();

  useEffect(() => {
    if (displayedCardsIndex + dCards > displayCards.length) {
      const newCard = initCards[initCardsIndex];
      if (!!newCard) {
        setDisplayCards((prev) => [...prev, newCard]);
        setInitCardsIndex((prev) => prev + 1);
      }
    }
  }, [displayedCardsIndex, initCards, initCardsIndex, displayCards]);

  const onSkipButtonClick = useCallback(() => {
    if (!swiperRef) return;
    const currentCard = displayCards[displayedCardsIndex];
    if (!!currentCard) {
      currentCard.skip = !currentCard.skip;
      setDisplayCards((prev) => [...prev]);
    }
  }, [displayedCardsIndex, displayCards, swiperRef]);

  const onWrongButtonClick = useCallback(() => {
    if (!swiperRef) return;
    const currentCard = displayCards[displayedCardsIndex];
    if (!currentCard) throw new Error('Something went wrong!!!');
    const isCardPending = isCardAlreadyPending(
      currentCard,
      displayCards,
      displayedCardsIndex
    );
    if (!isCardPending) {
      setDisplayCards((prev) => [...prev, currentCard]);
    }
    setTimeout(() => {
      swiperRef.slideNext();
    }, 100);
  }, [displayCards, displayedCardsIndex, swiperRef]);

  const onRightButtonClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  const MyButtons = useMemo(
    () => (
      <div className={styles.buttonWrapper}>
        <div className={styles.skipButton} onClick={onSkipButtonClick}>
          {/* SKIP */}
          <IonIcon icon={alertCircleOutline}></IonIcon>
        </div>
        <div className={styles.wrongButton} onClick={onWrongButtonClick}>
          {/* WRONG */}
          <IonIcon icon={thumbsDownOutline} color={'black'}></IonIcon>
        </div>
        <div className={styles.rightButton} onClick={onRightButtonClick}>
          {/* RIGHT */}
          <IonIcon icon={thumbsUpOutline} color={'black'}></IonIcon>
        </div>
      </div>
    ),
    [onSkipButtonClick, onWrongButtonClick, onRightButtonClick]
  );

  // console.log(displayCards);

  return (
    <div className={styles.cardsContainer} onClick={() => {}}>
      <div className={styles.swiperWrapper}>
        <>
          {!false && (
            <>
              <Swiper
                // Events found here: https://swiperjs.com/swiper-api#events
                onSlideChangeTransitionEnd={() => {
                  setDisplayedCardsIndex(swiperRef?.activeIndex);
                }}
                onSlideNextTransitionStart={() => {
                  // console.log('Moving right', swiperRef);
                }}
                onSlidePrevTransitionStart={() => {
                  // console.log('Moving left');
                }}
                onTouchMove={(arg: any) => {
                  // console.log(arg);
                }}
                modules={[Virtual, Navigation, Pagination]}
                onSwiper={(swiper: any) => {
                  setSwiperRef(swiper);
                }}
                slidesPerView={1}
                centeredSlides={true}
                spaceBetween={300}
                pagination={{ type: 'fraction' }}
                speed={250}
                navigation={document.documentElement.clientWidth > 500}
                virtual
              >
                {displayCards.map((displayCard, index) => (
                  <SwiperSlide key={index} virtualIndex={index}>
                    <BasicCard card={displayCard} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </>
      </div>
      {MyButtons}
    </div>
  );
};

/**
 * Test if a given card at a given position is found later on in a given pack
 */
function isCardAlreadyPending(card: ICard, pack: ICard[], activeIndex: number) {
  // --->
  console.log('Working?');
  const cardHash = cardToHash(card);
  console.log('cardHash', cardHash);
  for (let i = activeIndex + 1; i < pack.length; i++) {
    const card = pack[i];
    if (!!card && cardToHash(card) === cardHash) return true;
    console.log('iii', i, cardToHash(card!));
  }
  return false;
}

function placeCardAtFrontOfPack(pack: ICard[], card?: ICard): ICard[] {
  // --->
  if (!card) return pack;
  const indexOfCard = pack.map((card) => cardToHash(card)).indexOf(cardToHash(card));
  if ([-1, 0].includes(indexOfCard)) return pack;

  const part1: ICard[] = pack.slice(0, indexOfCard - 1);
  const part2: ICard[] = pack.slice(indexOfCard + 1, pack.length - 1);
  return [card, ...part1, ...part2];
}

import { ICardLanguage } from '../models/ICardLanguage';

/**
 * Receives responses for different languages and outputs a single MD
 * string to display this info
 */
export const basicCardLanguagesFormatter = (langCard: ICardLanguage) => {
  // --->>

  const output = Object.keys(langCard.languages)
    .map((key, ind) => {
      const y = langCard.languages[key as keyof typeof langCard.languages];
      const x = `${key.toUpperCase()}:\n\n ${y} \n\n `;
      return x.trim();
    })
    .join(' \n\n ');
  return output;
};

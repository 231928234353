import { DeckGrid } from '../DeckGrid';
import styles from './styles.module.scss';

export const PageHome = () => {
  return (
    <div className={styles.homePageContainer}>
      <DeckGrid />
    </div>
  );
};

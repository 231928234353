import { useEffect, useState } from 'react';
import { IonChip } from '@ionic/react';

import { basicCardSequenceFormatter } from '../../utils/basicCardSequenceFormatter';
import { basicCardLanguagesFormatter } from '../../utils/basicCardLanguagesFormatter';
import { supplementToString } from '../../utils/supplementToString';
import { markdownToHtml } from '../../utils/markdownToHtml';
import { ICardLanguage } from '../../models/ICardLanguage';
import { ICardSequence } from '../../models/ICardSequence';
import { ICard } from '../../models/ICard';

import styles from './styles.module.scss';

interface IProps {
  card: ICard;
}

/**
 * Super simple card to display 3 different sides
 */
export const BasicCard: React.FC<IProps> = ({ card }) => {
  // --->>

  const [cycle, setCycle] = useState<0 | 1 | 2>(0);

  useEffect(() => {
    setCycle(0);
  }, [card]);

  const sequenceText = card.sequence
    ? basicCardSequenceFormatter(card as any as ICardSequence)
    : '';
  const languagesText = card.languages
    ? basicCardLanguagesFormatter(card as any as ICardLanguage)
    : '';

  let md = card.front;
  if (cycle === 1) md = card.back || sequenceText || languagesText || '';
  if (cycle === 2) md = supplementToString(card.supplement || '');

  const __html = markdownToHtml(md);

  const goCycle = () => {
    setCycle((cycle) => {
      if (cycle === 0) return 1;
      if (cycle === 1 && card.supplement) return 2;
      return 0;
    });
  };

  // let backgroundColor = '#FEEEF5';
  let backgroundColor = 'white';
  if (cycle === 1) backgroundColor = '#E0EDF4';
  if (cycle === 2) backgroundColor = '#FBFBEF';

  const border = `solid 10px ${card.skip ? 'red' : 'transparent'}`;

  return (
    <div className={styles.basicCardContainer} onClick={goCycle}>
      <div
        className={styles.basicCardOutline}
        style={{
          backgroundColor,
          border,
        }}
      >
        <div className={styles.mainText} dangerouslySetInnerHTML={{ __html }} />
        <div className={styles.topicsText}>
          {card.topics.map((topic, ind) => (
            <IonChip //
              key={ind}
              outline={true}
              disabled={!true}
              color="primary"
            >
              {topic}
            </IonChip>
          ))}
        </div>
      </div>
    </div>
  );
};

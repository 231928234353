import { indentReset } from './indentReset';

/**
 * Function takes markdown string as input, outputs markdown with
 * indents cleaned up and excess white space removed. This is used to clean up
 * the markdown created in ts files that are formatted for visual neatness but
 * would otherwise translate to the db with excess and untidy whitespace.
 *
 * Most of the work is actually done in the indentReset function it turns out
 */
export function cleanMd(text: string) {
  const output = indentReset(text).replace(/\n /g, '\n').replace(/\:\n/g, ':\n\n');
  return output;
}

// // Test cleanMd on cards from pre-made decks

// import { data } from '../../../mydata/decks/angular_deck';

// // data.cards.forEach(el => console.log(el));

// const testCard = data.cards[0];

// console.log('--------');
// console.log(testCard);

// console.log('--------');
// testCard.front = cleanMd(testCard.front);
// console.log(testCard);

// // const x = cleanMd()

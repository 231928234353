import { useContext, useEffect, useRef, useState } from 'react';
import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonIcon,
} from '@ionic/react';
import { close } from 'ionicons/icons';

import { cardHeightPx } from '../../constants/layout';
import { ActiveCardsContext } from '../../providers/ActiveCardsProvider';
import { DecksContext } from '../../providers/DecksProvider';
import { fetchDecks } from '../../utils/fetchDecks';
import { modifyDecks } from '../../utils/modifyDecks';
import { IDeck } from '../../models/IDeck';
import { cardToHash } from '../../utils/cardToHash';
import { CardHashesContext } from '../../providers/CardHashesProvider';
import { cleanDecks } from '../../utils/cleanDecks';
import { flagSkippedDecks } from '../../utils/flagSkippedDecks';

import styles from './styles.module.scss';
import { Cards } from '../Cards';

const defaultDeck: IDeck = {
  cards: [],
  deckName: 'skipped',
};

/**
 * The main part of the interface that lays out the decks as a grid
 */
export const DeckGrid = () => {
  // --->>

  // ----------------------------------------
  // From: ...
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  function confirm() {
    modal.current?.dismiss(input.current?.value, 'confirm');
  }
  // ----------------------------------------

  const { cardHashes } = useContext(CardHashesContext);
  const { decksState, setDecksState } = useContext(DecksContext);
  const { setActiveCardsState } = useContext(ActiveCardsContext);

  const [decks, setDecks] = useState(decksState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [skippedCardsDeck, setSkippedCardsDeck] = useState<IDeck>(defaultDeck);

  const renderDecks = [skippedCardsDeck].concat(decks);

  const [modalTitle, setModalTitle] = useState('');

  useEffect(() => {
    const combinedCardsDeck = decks.find((deck) => deck.deckName === 'all');
    if (!combinedCardsDeck) return;
    const skippedCards = combinedCardsDeck.cards.filter((card) => {
      const hash = cardToHash(card, 'DeckGrid');
      return cardHashes.includes(hash);
    });
    setSkippedCardsDeck({ deckName: 'skipped', cards: skippedCards });
  }, [cardHashes, decks]);

  useEffect(() => {
    (async () => {
      const fetchedDecks = await fetchDecks();
      const modifiedDecks = modifyDecks(fetchedDecks);
      const cleanedDecks = cleanDecks(modifiedDecks);
      const flaggedSkippedDecks = flagSkippedDecks(cleanedDecks, cardHashes);
      setDecksState(flaggedSkippedDecks);
    })();
  }, [setDecksState, cardHashes]);

  useEffect(() => {
    if (inputText) {
      setDecks(
        decksState.filter((deck) => deck.deckName.includes(inputText.toLowerCase()))
      );
    } else {
      setDecks(decksState);
    }
  }, [inputText, decksState]);

  return (
    <div className={styles.deckGridContainer}>
      <IonModal
        ref={modal}
        isOpen={isModalOpen}
        trigger="trigger-button"
        onDidDismiss={() => setIsModalOpen(false)}
        style={{}}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsModalOpen(false)}>
                <IonIcon icon={close} color={'black'}></IonIcon>
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">{modalTitle}</IonTitle>
            {false && (
              <IonButtons slot="end">
                <IonButton strong={true} onClick={() => confirm()}>
                  Confirm
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
        </IonHeader>

        <IonContent scroll-y="false">
          <Cards closeModal={() => setIsModalOpen(false)} />
        </IonContent>
      </IonModal>

      {/*  */}
      <div className={styles.inputWrapper}>
        <div>{'Filter:  '}</div>
        <input
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          placeholder="Enter Text"
        />
      </div>

      {renderDecks.map((deck, ind0) => {
        return (
          <div key={ind0}>
            {deck.cards.length ? (
              <div className={styles.deckTitleWrapper}>
                <>{deck.deckName}</>
              </div>
            ) : null}
            <div className={styles.deckRow} style={{}}>
              {deck.cards.map((card, ind1) => {
                const border = `5px solid ${card.skip ? 'red' : 'transparent'}`;
                return (
                  <div
                    key={ind1}
                    className={styles.card}
                    style={{ height: cardHeightPx, border }}
                    onClick={() => {
                      setModalTitle(
                        deck.deckName.toLocaleUpperCase() +
                          ` (${deck.cards.filter((card) => !card.skip).length} Cards)`
                      );
                      setActiveCardsState({ cards: deck.cards, currentCard: card });
                      setIsModalOpen(true);
                    }}
                  >
                    {card.front}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

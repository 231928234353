import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ICard } from '../models/ICard';

interface IActiveCards {
  cards: ICard[];
  currentCard?: ICard;
}

const defaultActiveCardsState: IActiveCards = {
  cards: [],
  currentCard: undefined,
};

export const ActiveCardsContext = createContext<{
  activeCardsState: IActiveCards;
  setActiveCardsState: Dispatch<SetStateAction<IActiveCards>>;
}>({
  activeCardsState: defaultActiveCardsState,
  setActiveCardsState: () => [],
});

export const ActiveCardsProvider: React.FunctionComponent = ({ children }) => {
  const [activeCardsState, setActiveCardsState] = useState(defaultActiveCardsState);
  return (
    <ActiveCardsContext.Provider value={{ activeCardsState, setActiveCardsState }}>
      {children}
    </ActiveCardsContext.Provider>
  );
};

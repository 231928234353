import { IonButton, IonContent, IonModal, useIonActionSheet } from '@ionic/react';

interface Props {
  router: HTMLIonRouterOutletElement | null;
}

// import React from 'react';
// import AppReactComponent from './AppReactComponent';
// import { IonModal, IonContent, IonButton, useIonActionSheet } from '@ionic/react';
export const PageTest: React.FC<Props> = ({ router }) => {
  // --->>

  const [present, dismiss] = useIonActionSheet();

  const canDismiss: any = () => {
    return new Promise(async (resolve) => {
      await present({
        header: 'Are you sure you want to discard your changes?',
        buttons: [
          {
            text: 'Discard Changes',
            role: 'destructive',
          },
          {
            text: 'Keep Editing',
            role: 'cancel',
          },
        ],
        onDidDismiss: (ev: CustomEvent) => {
          const role = ev.detail.role;

          if (role === 'destructive') {
            resolve(true);
          }

          resolve(false);
        },
      });
    });
  };
  // );
  // }

  console.log('router', router);

  return (
    <>
      {/* Default */}
      {/* <IonModal isOpen={true}> */}
      {/* <IonContent>Modal Content</IonContent> */}
      {/* </IonModal> */}

      {/* Use a trigger */}
      {/* <IonButton id="trigger-button">Click to open modal</IonButton> */}
      {/* <IonModal trigger="trigger-button"> */}
      {/* <IonContent>Modal Content</IonContent> */}
      {/* </IonModal> */}

      {/* Sheet Modal */}
      {/* <IonModal isOpen={true} breakpoints={[0.1, 0.5, 1]} initialBreakpoint={0.5}>
        <IonContent>Modal Content</IonContent>
      </IonModal> */}

      {/* Card Modal */}
      <IonModal isOpen={true} swipeToClose={true} presentingElement={router || undefined}>
        <IonContent>Modal Content</IonContent>
      </IonModal>

      {/* Passing Props */}
      {/* <IonModal isOpen={true}> */}
      {/* <AppReactComponent title="Ionic"></AppReactComponent> */}
      {/* </IonModal> */}

      {/* Require Action Sheet confirmation before dismissing */}
      {/* <IonModal isOpen={true} canDismiss={canDismiss}>
        <IonContent>Modal Content</IonContent>
      </IonModal> */}
    </>
  );
};

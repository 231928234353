import { createContext, Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { emptyArrayThatMustRetainGlobalReference } from '../utils/emptyArrayThatMustBeConst';

const defaultCardHashes: string[] = [];

export const CardHashesContext = createContext<{
  cardHashes: string[];
  setCardHashes: Dispatch<SetStateAction<string[]>>;
}>({
  cardHashes: defaultCardHashes,
  setCardHashes: () => [],
});

export const CardHashesProvider: React.FunctionComponent = ({ children }) => {
  const [cardHashes, setCardHashes] = useLocalStorage<string[]>(
    'SKIPPED_CARD_HASHES',
    emptyArrayThatMustRetainGlobalReference
  );
  return (
    <CardHashesContext.Provider value={{ cardHashes, setCardHashes }}>
      {children}
    </CardHashesContext.Provider>
  );
};

import { ICard } from '../models/ICard';

/**
 * Generate simple numbered cards for dev/testing
 */
export const generateCards = (n = 10) => {
  //

  const cards: ICard[] = [];

  for (let i = 0; i < n; i++) {
    const card: ICard = {
      // front: `Card ${i} Front`,
      front: `${i}-Fake-Card`,
      back: `Card ${i} Back`,
      supplement: `
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
        Sth really long
      `,
      topics: ['TEMPLATE', 'agile', 'ancient-near-east', 'antarctica'],
    };
    cards.push(card);
  }

  return cards;
};

import { v5 as uuidv5 } from 'uuid';

import { ICard } from '../models/ICard';

const MY_SEED = '1b671a64-40d5-491e-99b0-da01ff1f3341';

/**
 * ...
 */
export const cardToHash = (card: ICard, debug?: string) => {
  // --->>

  const languages = card.languages
    ? Object.keys(card.languages).join() + Object.values(card.languages).join()
    : '';

  const frontAndBack = card.front + ' <--> ' + (card.back || languages);

  const hash = uuidv5(frontAndBack, MY_SEED);

  return hash;
};

import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { PagesLayout } from '../PagesLayout';
import { PageHome } from '../PageHome';
import { Menu } from '../Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Swiper.js stuff */
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

/* My customizations of swiper js stuff */
import '../../styles/swiper.scss';

/* Theme variables */
import '../../theme/variables.scss';
import { useRef } from 'react';
import { PageTest } from '../PageTest';

setupIonicReact();

export const AppEntry: React.FC = () => {
  // --->>

  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main" ref={routerRef}>
            {/*
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            */}

            <Route path="/" exact={true}>
              <PagesLayout title={'FLASHHEART'}>
                <PageHome />
              </PagesLayout>
            </Route>

            <Route
              path="/test"
              render={() => <PageTest router={routerRef.current} />}
              exact={true}
            />

            {/* If page not found */}
            <Redirect to="/" />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { IDeck } from '../models/IDeck';

const defaultDecksState: IDeck[] = [];

export const DecksContext = createContext<{
  decksState: IDeck[];
  setDecksState: Dispatch<SetStateAction<IDeck[]>>;
}>({
  decksState: defaultDecksState,
  setDecksState: () => [],
});

export const DecksProvider: React.FunctionComponent = ({ children }) => {
  const [decksState, setDecksState] = useState(defaultDecksState);
  return (
    <DecksContext.Provider value={{ decksState, setDecksState }}>
      {children}
    </DecksContext.Provider>
  );
};

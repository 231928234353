/**
 *
 */
export const fetchDecks = async () => {
  // --->>

  const decks = await fetch('/decks.json', {})
    .then((res) => res.json())
    .catch((_) => {
      console.log('Error while fetching!');
    });

  return decks;
};

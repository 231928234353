import { ICardSequence } from '../models/ICardSequence';

let isStylesAdded = false;

/**
 * Extremely basic function to map sequence to something printable
 * To get nice functionality though, we'll need to use a proper JSX component
 * with its own state
 */
export const basicCardSequenceFormatter = (sequenceCard: ICardSequence) => {
  // --->>

  if (!isStylesAdded) {
    isStylesAdded = true;
    const style = document.createElement('style');
    style.innerHTML = `
      .sequenceText {
        /* opacity: 0; */
        /* background-color: green; */
      }
    `;
    document.head.appendChild(style);
  }

  const formattedEntries = sequenceCard.sequence.map((seq) => {
    if (typeof seq === 'string') {
      return seq;
    } else {
      const joinedText = Array.isArray(seq.text) ? seq.text.join(', ') : seq.text;
      return `*${seq.date}*: <span class="sequenceText"> ${joinedText}</span>
        <br><div class="sequenceText" style="font-size: 8px; font-style: italic; transform: translateY(-10px);">${seq.add}</div>`;
    }
  });

  return formattedEntries.join('\n\n');
};

import { cleanMd } from './cleanMd';
import { supplementToString } from './supplementToString';
import { ICard } from '../models/ICard';

/**
 * Essentially a wrapper around cleanMd except we pass a card through and clean
 * up the markdown in the major fields: front, back, etc.
 */
export function cleanCardMd(inCard: ICard): ICard {
  // --->>

  // Copy card as is:
  const outCard: ICard = { ...inCard };

  // Clean front:
  outCard.front = cleanMd(outCard.front);

  // Clean back (if exists)
  if (!!outCard.back) outCard.back = cleanMd(outCard.back);

  // Clean supplement (if exists)
  if (!!outCard.supplement) {
    outCard.supplement = cleanMd(supplementToString(outCard.supplement));
  }

  if (!!outCard.languages) {
    // Loop through any language entries and clean them
    Object.keys(outCard.languages).forEach((lang) => {
      type TLang = keyof typeof outCard.languages;
      outCard.languages[lang as TLang] = cleanMd(outCard.languages[lang as TLang]!);
    });
  }
  return outCard;
}

// // Test cleanMd on cards from pre-made decks

// import { data } from '../../../mydata/decks/angular_deck';
// import { data } from '../../../mydata/decks/german_deck';

// // // data.cards.forEach(el => console.log(el));

// const testCard = data.cards[0];

// console.log('--------');
// console.log(testCard);

// console.log('--------');
// const testCard2 = cleanCardMd(testCard);
// console.log(testCard2);

// const x = cleanMd()

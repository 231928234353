import { ICard } from '../models/ICard';

/**
 *
 */
export const randomizeCards = (cards: ICard[]) => {
  // --->>

  return cards.sort(() => Math.random() - 0.5);
};

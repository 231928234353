/**
 * ...
 */
export function indentReset(text: string) {
  const lines = text.split('\n');

  let leastSpacesOfNonEmptyLine = 9999999;

  lines.forEach((line) => {
    const isLineEmpty = !line.replace(/\s+/g, '');
    let spaces = -1;
    if (!isLineEmpty) {
      spaces = line.split(/\S/)[0]!.length;
      if (spaces < leastSpacesOfNonEmptyLine) leastSpacesOfNonEmptyLine = spaces;
    }
    // console.log(js(line), isLineEmpty, spaces);
  });

  lines.forEach((line, ind) => {
    lines[ind] = line.replace(' '.repeat(leastSpacesOfNonEmptyLine), '').trimRight();
  });

  // console.log('++++++++++++++++');
  // console.log(lines.join('\n'));
  // console.log('++++++++++++++++');
  // console.log('>>>>', leastSpacesOfNonEmptyLine);

  return lines.filter(Boolean).join('\n');
}

// console.log('----------');
// const card = data.cards[3];
// console.log(card);
// console.log(card.supplement);
// console.log('----------');

// card.front = indentReset(card.front);
// card.supplement = indentReset(card.supplement || '');
// card.back = indentReset(card.back || '');

// console.log(card);
// console.log(card.supplement);

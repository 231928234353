import { ICard } from '../models/ICard';
import { IDeck } from '../models/IDeck';
import { randomizeCards } from './randomizeCards';

/**
 * E.g. combine all decks into single one
 */
export const modifyDecks = (decks: IDeck[]) => {
  // --->>

  const combinedCards = decks
    .map((deck) => deck.cards)
    .reduce((acc: ICard[], card) => {
      return acc.concat(card);
    }, []);
  const combinedDeck: IDeck = {
    cards: randomizeCards(combinedCards),
    deckName: 'all',
    owner: 'magnus',
    private: true,
  };
  return [combinedDeck, ...decks];
};

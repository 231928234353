import * as MarkdownIt from 'markdown-it';

/**
 * Wrapper around a lib to convert md to html
 * Tried `marked` but it didn't give nested lists
 * Now using `markdown-it`
 */
export const markdownToHtml = (md: string) => {
  return new MarkdownIt.default({
    html: true,
  }).render(md);
};
